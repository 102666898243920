let doc = $(document),
    win = $(window),
    html = $("html"),
    body = $("body"),
    lang = html.attr('lang');

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        if (!html.hasClass("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function(e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            if (location.protocol === 'https:') {
                navigator.serviceWorker.getRegistrations().then(function(registrations) {
                    for(let registration of registrations) {
                        registration.unregister()
                    }
                });
            }
        }
    });
}

(function($) {
    cssLoaded(function(){
        body.removeClass("mod--preload");
        setTimeout(function () {
            body.addClass("mod--loaded");
        }, 300);
    });
})(jQuery);