const fn = $(".part_order_form");

if (fn.length) {
    fn.on("change", "[data-form-toggle]", function () {
        $(this).closest(".wrp_form_toggle").next(".wrp_form_body").fadeToggle(0);
    });

    //check po loadu stránky
    $('[data-form-toggle]').each(function() {
        if($(this).prop('checked') === true) {
            $(this).closest(".wrp_form_toggle").next(".wrp_form_body").show()
        }
    });

}