(function($) {
    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    nl_lib_dialog.init("html", function(){
        doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });

    $(".part_ui_btn").nl_lib_ripple();

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        var formButton = $(this).find('button[type="submit"]');
        formButton.attr('disabled','disabled');

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);

                setTimeout(function() {
                    formButton.removeAttr('disabled');
                }, 1000);
            },
            error: function(jqXHR, textStatus, errorThrown ) {
                setTimeout(function() {
                    formButton.removeAttr('disabled');
                }, 1000);
            }
        });
    });

    if (body.filter("[data-dialog-open]").length) {
        $.ajax({
            dataType: 'json',
            url: body.data('dialog-open')
        }).done(function (data) {
            nl_lib_dialog.open(data.dialog);
        });
    }

    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }

    doc.on('submit','form.part_form:not(.ajax)', function(e) {
        $(this).find('button[type="submit"]').attr('disabled','disabled');
    });

    doc.on('click','[data-apply-promo-code]', function() {
        var promoCode = $('input#promoCode').val();

        var promoButton = $(this);
        promoButton.attr('disabled','disabled');

        $.ajax({
            method: 'post',
            dataType: 'json',
            data: {do: 'applyCode', code: promoCode}
        }).done(function (data) {

            fn_ajaxHandler(data, function(cb) {

            });

            setTimeout(function() {
                promoButton.removeAttr('disabled');
            },1000);
        });
    });

    $('form[data-append-filter]').antiSpam();


})(jQuery);