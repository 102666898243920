(function($) {
    const fn = $(".part_order_products");

    if (fn.length) {

        numeral.localeData().delimiters.thousands = ' ';
        numeral.defaultFormat('0,0');

        function recountOrderSum()
        {
            let sumProducts = 0;

            $('[data-product-count]').each(function() {
                let count = parseInt($(this).val()) || 0;
                let price = parseFloat($(this).data('unit-price')) || 0;

                let sumProduct = price * count;
                sumProducts += sumProduct;

            });

            $('#orderTotalSum').text(numeral(sumProducts).format());
        }

        fn.on('change','[data-product-count]', function() {
            recountOrderSum();

            let idProduct = parseInt($(this).data('product-id')) || 0;
            let count = parseInt($(this).val()) || 0;

            /*$.ajax({
                method: 'post',
                dataType: 'json',
                data: {do: 'setProductToBasket', idProduct: idProduct, count: count}
            }).done(function (data) {

            });*/

        });

        recountOrderSum();
    }
})(jQuery);
