(function($) {

    var disabledDatesReturnProg = [];
    var datepickerPicking, datepickerReturn;
    var dateTimeCheckInterval;
    var toMinuteSeconds;

    var textReserved;
    if($('.part_order_form').length) {
        textReserved = $('.part_order_form').attr('data-text-reserved');
    }

    const fn = $(".part_ui_input");

    if (!html.hasClass("ie")) {
        $.fn.validation = function () {
            $(this).removeClass("mod--invalid mod--valid");
            if ($(this).find("input, textarea").val() !== "") {
                if ($(this).find("input, textarea").is(":valid")) {
                    $(this).addClass("mod--valid");
                } else {
                    $(this).addClass("mod--invalid");
                }
            }
        };

        doc.on("change", ".part_ui_input", function () {
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validation();
            }
        });

        if (fn.length) {
            fn.each(function () {
                if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("mod--invalid")) {
                    $(this).validation();
                }
            });
        }
    }

    function getDisabledDatesPicking(date, cellType)
    {
        return getDisabledDates(date, cellType,'picking');
    }

    function getDisabledDatesReturn(date, cellType)
    {
        return getDisabledDates(date, cellType,'return');
    }

    function getDisabledDates(date, cellType, dateType) {
        if (cellType === 'day') {
            var isDisabled = false;

            var day = date.getDate().toString();
            if(day.length === 1) {
                day = '0' + day;
            }
            var month = date.getMonth() + 1;
            month = month.toString();
            if(month.length === 1) {
                month = '0' + month;
            }
            var year = date.getFullYear();
            var string = year + '-' + month + '-' + day;

            if(dateType === 'picking' && typeof disabledDatesPicking !== 'undefined') {
                var disabledDates = disabledDatesPicking;
            } else if(dateType === 'return' && typeof disabledDatesReturn !== 'undefined') {

                var disabledDates = disabledDatesReturn.concat(disabledDatesReturnProg);
            }

            if(typeof disabledDates !== 'undefined') {
                if(disabledDates.indexOf(string) !== -1) {
                    isDisabled = true;
                }
            }
            return {
                disabled: isDisabled
            }
        }
    }

    function displayTimeSessionExpireError(selector)
    {
        $(selector).fadeIn(300);

        setTimeout(function() {
            $(selector).fadeOut(400);
        }, 4000);
    }

    function checkDateReturnVal(disabledDates) {
        disabledDatesReturnProg = disabledDates;

        $('select[name="timeReturn"]').val('');
        $('[datepicker-return]').find('input').val('').trigger('change');

    }

    function checkValueDate(selector, disDates, type)
    {
        var currentDateVal = $(selector).val().toString();
        if(currentDateVal.length === 0) {
            return null;
        }
        currentDateVal = currentDateVal.trim();
        var selectedDateMoment = moment(currentDateVal, "DD.MM.YYYY");

        $.each(disDates, function(index, val) {

            var disDateMoment = moment(val, "YYYY-MM-DD");
            if(disDateMoment.isSame(selectedDateMoment)) {
                $(selector).val('');
                switch (type) {
                    case 'picking':
                        displayTimeSessionExpireError('[data-date-error-picking]');
                        break;
                    case 'return':
                        displayTimeSessionExpireError('[data-date-error-return]');
                        break;
                }
            }
        });
    }

    function disableSelectTimeValues(select, times, type, fromInterval) {

        var selectElem = $(select);
        var currentVal = selectElem.val();

        var selectOptions = $(select + ' option');
        var i = 0;
        selectOptions.each(function() {
            i++;
            if(i > 1) {
                $(this).removeAttr('disabled');
                $(this).removeAttr('selected');
                var currSval = $(this).attr('value');
                $(this).text(currSval);
            }
        });

        var fillBack = true;

        $.each(times, function(index, val) {
            if(currentVal == val) {
                selectElem.val('');
                fillBack = false;
                if(fromInterval) {
                    switch (type) {
                        case 'picking':
                            displayTimeSessionExpireError('[data-time-error-picking]');
                            break;
                        case 'return':
                            displayTimeSessionExpireError('[data-time-error-return]');
                            break;
                    }
                }
            }
            selectElem.find('option[value="' + val + '"]').attr('disabled','disabled');
            selectElem.find('option[value="' + val + '"]').text(val + ' ' + textReserved);
        });

        if(fillBack) {
            selectElem.val(currentVal);
        }

    }

    function updateAvailableDateTimes()
    {

        var currentDatePicking = $("[datepicker-picking]").find("input").val();
        var currentDateReturn = $("[datepicker-return]").find("input").val();
        var currentTimePicking = $('select[name="timePicking"]').val();
        var currentTimeReturn = $('select[name="timeReturn"]').val();

        $.ajax({
            method: 'post',
            dataType: 'json',
            data: {
                do: 'getDisabledDateTimes',
                datePicking: currentDatePicking,
                dateReturn: currentDateReturn,
                timePicking: currentTimePicking,
                timeReturn: currentTimeReturn
            }
        }).done(function (data) {
            console.log('check done');

            if(typeof data.timePicking !== 'undefined' && data.timePicking.length) {
                disableSelectTimeValues('select[name="timePicking"]', data.timePicking, 'picking', true);
            }
            if(typeof data.timeReturn !== 'undefined' && data.timeReturn.length) {
                disableSelectTimeValues('select[name="timeReturn"]', data.timeReturn, 'return', true);
            }

            //check dates
            if(typeof data.datePicking !== 'undefined' && data.datePicking.length) {
                checkValueDate('input[name="datePicking"]', data.datePicking, 'picking');
            }
            if(typeof data.dateReturn !== 'undefined' && data.dateReturn.length) {
                checkValueDate('input[name="dateReturn"]', data.dateReturn, 'return');
            }

        });
    }

    if($('div.part_order_form').length) {
        updateAvailableDateTimes();

        var currDate = new Date();
        toMinuteSeconds = 60 - currDate.getSeconds();

        toMinuteSeconds -= 2;
        if(toMinuteSeconds < 0) {
            toMinuteSeconds = 0;
        }

        setTimeout(function() {
            updateAvailableDateTimes();
            dateTimeCheckInterval = setInterval(updateAvailableDateTimes, 1000*60);
        },1000*toMinuteSeconds);
    }

    if (fn.length) {
        $.getScript(cdnjs.datepicker).done(function() {

            var setDPickerLang = true;
            if(lang == 'ru') {
                lang = 'en';
                setDPickerLang = false;
            }

            $.getScript(cdnjs.datepicker_i18n.replace(".en.",`.${lang}.`)).done(function() {

                datepickerPicking = fn.filter("[datepicker-picking]").find("input").datepicker({
                    language: setDPickerLang ? lang : null,
                    position: "top center",
                    minDate: new Date(),
                    autoClose: true,
                    dateFormat: 'dd.mm.yyyy',
                    onSelect: function( formattedDate, date, inst) {
                        $.ajax({
                            method: 'post',
                            dataType: 'json',
                            data: {do: 'getDisabledTimes', date: formattedDate, type: 'picking'}
                        }).done(function (data) {
                            disableSelectTimeValues('select[name="timePicking"]', data.disabledTimes, 'picking', false);
                            checkDateReturnVal(data.disabledDatesReturn);
                        });

                        datepickerReturn.data('datepicker').update({minDate: new Date(date.getTime() + 48 * 60 * 60 * 1000)});

                    },
                    onRenderCell: getDisabledDatesPicking
                });

                datepickerReturn = fn.filter("[datepicker-return]").find("input").datepicker({
                    language: lang,
                    position: "top center",
                    minDate: new Date(),
                    autoClose: true,
                    dateFormat: 'dd.mm.yyyy',
                    onSelect: function( formattedDate, date, inst) {
                        var selectedTimePicking = $('select[name="timePicking"]').val();
                        var selectedDatePicking = $('[datepicker-picking]').find('input').val();
                        $.ajax({
                            method: 'post',
                            dataType: 'json',
                            data: {do: 'getDisabledTimes', date: formattedDate, timePicking: selectedTimePicking, datePicking: selectedDatePicking, type: 'return'}
                        }).done(function (data) {
                            disableSelectTimeValues('select[name="timeReturn"]', data.disabledTimes, 'return', false);
                        });
                    },
                    onRenderCell: getDisabledDatesReturn
                });
            });
        });
    }
})(jQuery);