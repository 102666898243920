(function($) {
    let fn = $("#layout_header"),
        header = fn.find(".wrp_header_body");

    if (fn.length) {
        fn.after('<nav id="layout_nav" class="ssm-nav" role="presentation"><div class="elm_head"></div><div class="elm_content"></div></nav>');

        let layout_nav = $(document).find("#layout_nav"),
            logo = header.find(".elm_body_logo").clone(),
            nav = header.find(".elm_body_nav").clone(),
            misc = header.find(".elm_body_misc").clone();

        layout_nav.find(".elm_head").append(logo);
        layout_nav.find(".elm_content").append(nav);
        layout_nav.find(".elm_content").append(misc);

        $('.ssm-nav').slideAndSwipe();

        let headroom = new Headroom(fn[0], {
            offset: 5,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    }
})(jQuery);