(function($) {
    const fn = $(".part_promo");

    if (fn.length) {
        win.on("scroll",function(){
            if ((win.scrollTop() + 80) > win.height()) {
                fn.addClass("mod--active");
            } else {
                fn.removeClass("mod--active");
            }
        });
    }
})(jQuery);