(function($) {
    const fn = $(".comp_faq");

    if (fn.length) {
        fn.on("click", "[data-toggle-faq]", function() {
            let elm = $(this),
                elm_each = fn.find(".elm_item [aria-expanded]").not(elm);

            elm_each.each(function(){
                if ($(this).attr("aria-expanded") === "true") {
                    $(this).attr("aria-expanded",false);
                    $(this).next(".wrp_item_body").slideUp(300).attr("aria-hidden",true);
                }
            });
            if (elm.attr("aria-expanded") === "false") {
                elm.attr("aria-expanded",true);
                elm.next(".wrp_item_body").slideDown(300).removeAttr("aria-hidden");
            } else {
                elm.attr("aria-expanded",false);
                elm.next(".wrp_item_body").slideUp(300).attr("aria-hidden",true);
            }
        });
    }
})(jQuery);